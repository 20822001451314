import React from "react";
import Button from "../../../DesignSystem/Button";
import Input from "../../../DesignSystem/Input";
import { Container } from "../../../styles/UI";

import { useInput } from "../../../hooks/useInput";

import Download from "../../../icons/download.inline.svg";

import * as S from "./style";
import { navigate } from "gatsby-link";
import mixpanel from "mixpanel-browser";

import axios from "axios";

export default function ApresentacaoHome(props) {
  const invalidDomains = [
    // "@gmail.com",
    // "@gmail.com.br",
    "@bol.com.br",
    "@uol.com.br",
    "@terra.com.br",
    "@ig.com.br",
    "@globo.com",
    "@zipmail.com.br",
    "@oi.com.br",
    "@superig.com.br",
    "@live.com",
    "@live.com.br",
    "@r7.com",
    "@msn.com",
  ];

  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
  } = useInput("", "email");

  const client = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const baixarApresentacao = () => {
    mixpanel.init("ac2da5c424bbdb8fbcac0d565783254a");
    mixpanel.track("Digitou email para baixar a apresentação", {
      email: email,
    });

    if (
      email === "" ||
      invalidDomains.some((domain) => email.toLowerCase().endsWith(domain)) ||
      isInvalidEmail
    ) {
      mixpanel.track("Digitou email inválido", {
        email: email,
      });
      alert("Digite seu email corporativo para baixar a apresentação");
      return;
    }

    const form = new URLSearchParams();

    form.append("source", "demonstracao");
    form.append("email", email);
    form.append("empresa", "");
    form.append("celular", "");
    form.append("nome", "");

    client.post(`/RD_Station.aspx`, form).then((resp) => {
      console.log(resp);
    });
    mixpanel.track("Baixou apresentação", {
      email: email,
    });

    if (typeof window !== "undefined")
      window.location.href = `/apresentacao.pdf`;
  };

  return (
    <S.Apresentacao $white={props.white}>
      <Container>
        <h6>Quer apresentar a Taxcel para sua empresa?</h6>
        <Input big center placeholder="Seu email corporativo" {...bindEmail}>
          <Button
            onClick={baixarApresentacao}
            size="big"
            iconColor="secondary"
            iconLeft
          >
            <Download />
            Baixar a apresentação
          </Button>
        </Input>
      </Container>
    </S.Apresentacao>
  );
}
